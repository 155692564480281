/* .body{
    font-size: 5px;
} */
p{
  margin-top: 0;
    margin-bottom: 1rem;
    line-height: 35px;
    font-size: 18px;
}
.p-over{
  text-align: right;
}

.BackgroundPic{
    background-image: url(../Images/BackgroundPic.jpg);
    width: 100%;
    height: 100vh;
    background-position: center ;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
  .Title-pic{
      height: 450px;
      width: 330px;
  }

  .opacity h1{
    text-align: right;
      font-size: 3.9rem;
  } 
  .opacity h5{
    text-align: center;
      font-size: 1.7rem;
  }
  
  .opacity{
      margin-top: 0;
    display: block;
    padding: 34px;
    background: rgba(66, 55, 56, 0.8);
    color: white;
    height: 50%;
    margin-bottom: 5%;
    overflow: auto;
    text-align: right;
    /* font-size: 24px; */
  }
  .opacity::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .opacity::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .opacity::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  .opacity::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  @media screen and (max-width: 992px){
    .opacity{
      height: 500px;
    }
  }
  @media screen and (max-width: 480px) {
    
    /* body {
      background: black;
    }
    .BackgroundPic{
      background-image: none;
  } */

  .opacity h1{
    text-align: center;
      font-size: 1.9rem;
  } 
  .opacity h5{
    text-align: right;
      font-size: 1rem;
  }
  .Title-pic{
    /* display: none; */
    display: block;
    margin-left: auto;
    margin-right: auto;  
    margin-top: 5%;
    height: 150px;
    width: 100px;
  }
  .opacity{
  background: rgba(66, 55, 56, 0.8);
  color: white;
  /* font-size: 24px; */
}
  .p-over{
    overflow: scroll;
    height: 220px;
  }
  }
  @media screen and (max-width: 768px) {
    .Title-pic{
      /* display: none; */
      display: block;
      margin-left: auto;
      margin-right: auto;  
      margin-top: 5%;
      height: 250px;
      width: 200px;
    }
    .opacity h1{
    text-align: center;
      font-size: 2 .5rem;
  } 
  .opacity h5{
    text-align: right;
      font-size: 1.4rem;
  }
  .Title-pic{
    /* display: none; */ 
    margin-top: 5%;
    height: 250px;
    width: 200px;
  }
  .opacity{
  background: rgba(66, 55, 56, 0.8);
  color: white;
  /* font-size: 24px; */
}
  }