.pdf-cards {
  /* padding: .5rem 1rem; */
  display: block;
  background-color: #C6CA9B;
  border-radius: 8px;
  box-shadow: 0 12px 32px 4px #212325;
  transition: .5s ease;
  width: 100%;
  margin-bottom: 24px;


}

.pdf-cards:hover {
  box-shadow: 1px 12px 32px 4px black;

}

.app {
  text-align: center;
}

.Videos {
  /* display: flex; */
  /* justify-content: center; */
  /* flex-wrap: wrap; */
  margin: 25px 10px 25px 10px;
}

.head-1 {
  display: block !important;
  color: white;
  background-image: url(../Images/bg-head.png);
  height: 150px;
  background-position: center;
  background-size: cover;
  text-align: center;
  position: relative;
}

.heads1 {
  position: absolute;
  top: 35%;
  left: 40%;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.afsana_article {
  padding: 40px;
}

.details {
  text-align: center;
  padding: 16px 4px 6px;
  background: #C6CA9B;
  overflow: hidden;
  z-index: 9;
  top: -4px;
  -moz-border-radius: 0 0 5px 5px;
  -webkit-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  min-height: 58px;
}

.article-name {
  font-size: 24px;
  color: #4c4c4c;
  margin-bottom: 8px;
  white-space: nowrap;
}

h1.heading {
  text-align: right;
  display: block;
}

.heading {
  text-align: right;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid white;
  margin: 15px 5px 30px 5px;
  color: white;
}

.buttons {
  background-color: none;
  background-color: #6e795d !important;
  border: 1px solid #33382a !important;
}

.shaer {
  text-align: right;
  font-size: 29px;
  margin-bottom: 40px;
  color: wheat;
}

.shaer-1 {
  text-align: right;
  font-size: 29px;
  margin-bottom: 40px;
  color: wheat;
}

.big {
  text-align: center;
  font-size: 29px;
  margin-bottom: 40px;
  color: wheat;
}

.big-1 {
  text-align: center;
  font-size: 29px;
  margin-bottom: 40px;
  color: wheat;
}

.row {
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .shaer {
    font-size: 23px;
  }

  .shaer-1 {
    margin-bottom: 0;
    font-size: 23px;
  }

  .big {
    font-size: 23px;
  }

  .big-1 {
    font-size: 23px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 480px) {
  .article-name {
    font-size: 15px;
  }

  .pdf-cards {
    margin-bottom: 24px;
  }

  .shaer {
    font-size: 15px;
  }

  .shaer-1 {
    margin-bottom: 0;
    font-size: 15px;
  }

  .big {
    font-size: 15px;
  }

  .big-1 {
    font-size: 15px;
    margin-bottom: 0px;
  }

  .afsana_article {
    padding: 14px;
  }

  .heads1 {
    left: 30%;
    font-size: 30px;
  }

  .Videos {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 25px 0px 25px 0px;
  }
}

@media screen and (max-width: 370px) {
  .heads1 {
    left: 27%;
    font-size: 30px;
  }
}