.heading-1{
    display: block !important;
    text-align: right;
    justify-content: space-between;
    border-bottom: 1px solid white;
    margin: 15px 5px 30px 5px;
    color: white;
}
.margin-1.col-md-4.col-6{
    padding: 10px;
}
