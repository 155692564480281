.w-80{
    margin: auto;
    height: 450px max;
   
}
.Title-pic1{
    height: 350px;
    width: 280px;
}
.GalleryContainer{
    display: flex;
    flex-wrap: wrap;
}
.margin{
    padding-left: 0px;
    margin-right: 0px;
}
.margin.col-md-4.col-6{
    padding: 0px;
    border-right: 3px solid #A1B08A;
    border-bottom: 2px solid #A1B08A;
}
.heading{
    display: block !important;
    text-align: right;
    justify-content: space-between;
    border-bottom: 1px solid white;
    margin: 15px 5px 30px 5px;
    color: white;
}
span{
    text-align: right;
}
span.left-1{
    text-align: left;
}
a{
  color: white !important;
}
.afsana_top{
    margin-top: 20px;
}

.opacity-1{
    margin-top: 0;
  display: block;
  padding: 34px;
  background: none;
  color: white;
  /* font-size: 24px; */
  margin-top: 20px;
}
.p-over{
    margin-top: 30px;
}
/* a.carousel-control-next {
    display: none;
}
a.carousel-control-prev {
    display: none;
} */
@media screen and (max-width: 480px){
    .opacity-1{
    padding: 0 30px 34px 34px;
    text-align: center;
    margin-top: 30px;
}
    .p-over{
    padding-top: 20px;
    text-align: right;
}
.Title-pic1{
    width: 180px;
    height: 200px;
}
.car-head{
    font-size: 0.83rem;
    font-weight: 900;
}
.car-head1{
    font-size: 1rem;
    font-weight: 100;
}
.car-head2{
    font-size: 1.75rem;
    font-weight: 900;
}
}

@media screen and (max-width: 768px){
    .opacity-1{
        text-align: center;
    }
}