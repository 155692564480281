.logo{
  font-size: 2.5rem;
  color: white;
  font-family: monospace;
  font-weight: 900;
}
.head{
  height: 93px;
  background-color: #282c2f;
}

.Nav-items{
  justify-content: flex-end;
  padding: 0 10px 0 10px;
}
div#basic-navbar-nav {
  justify-content: flex-end;
}
.nav-item{
  color: rgba(255,255,255);
  justify-content: space-between;
  font-size: 20px;
}
a.nav-link{
  color: white !important;
  text-align: center;
}
.navbar,
.navbar-header{
  background-color:#282c2f !important;
  color: white;
}
.main-content{
  margin-top: 92px;

}
.footer-set{
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  position : relative;
}
.span{
  position: relative;
}
@media screen and (max-width: 992px){
.navbar-light .navbar-toggler{
  background-color: white;
}
  .navbar-light .navbar-toggler{
    background-color: white;
  }
  .nav-item {
    text-align: end;
}
}
@media screen and (max-width: 480px){
  span.main-head{
    font-size: 1.5rem;
  }
  span.left-1{
    font-size: 1rem;
  }
  .logo{
    font-size: 1.5rem;
    color: white;
    font-family: emoji;
    font-weight: 900;
  }
  .navbar-light .navbar-toggler{
    background-color: white;
  }
  .nav-item {
    text-align: end;
    padding: 0;
}
.head{
  height: 73px;

}
.main-content{
  margin-top: 72px;

}

.carousel-indicators{
  display: none !important;
}
.carousel-caption{
  bottom: -40px !important;
}
}