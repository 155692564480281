* {
    box-sizing: border-box;
  }
  
  body {
    /* font-weight: 400; */
    font-family: sans-serif;
  }
  
  body, html, .App, #root, .outer {
    /* width: 100%;
    height: 100%; */
    background: #424B3F !important;
    /* background: white;  /*fallback for old browsers */
    /* background: -webkit-linear-gradient(to right, #f64f59, #c471ed, #12c2e9);  /* Chrome 10-25, Safari 5.1-6 */
     /*background: linear-gradient(to right, #383536, #232122, #cfdadfde);  */
    
  }
  
  .navbar-light {
    background-color: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  }
  
  .outer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  
  .inner {

    width: 530px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
    margin-top: 13%;
    margin-bottom: 13%;
  }
  
  .outer .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
  }
  
  .outer h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }
  
  .custom-control-label {
    font-weight: 400;
  }
  
  .forgot-password,
  .forgot-password a {
    text-align: right;
    font-size: 13px;
    padding-top: 10px;
    color: #7f7d7d;
    margin: 0;
  }
  
  .forgot-password a {
    color: #167bff;
  }

  @media screen and (max-width : 550px){
    .inner {

        width: auto;
    }
  }