.inner1{
    margin-top: 15%;
}

.main{
    margin: 25% auto;
    margin-left: 40px;
    margin-right: 40px;
}
.Checkboxes{
    color: white;
    text-align: center;
}
.Checkboxes p{
    color: red;
    text-align: center;
}
ul {
    display: flex;
    justify-content: space-between;
  }

@media screen and (max-width: 480px) {
    .main{
        margin: 25px;
        text-align: center;
        margin-top: 20%;
        margin-bottom: 20%;
    }
    button.btn.btn-success{
    margin-bottom: 20px;
    }
    .col-md-3.col-sm-4.m-auto{
        text-align: center;
    }
}