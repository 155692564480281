.Footer{
    display: flex;
    justify-content: space-between;
}

.bg-f{
    background-color: #282c2f !important;
}

@media screen and (max-width: 580px){
  .Footer{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
}